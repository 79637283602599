<template>
  <div>
    <div class="mt-3 mobile" :style="criandoSeguro ? 'position:fixed;' : ''">
      <div class="row">
        <div class="col-3 col-sm-2 box-line-miguel">
          <img src="/img/Untitled-1.svg" class="mt-4" height="65" />
        </div>
        <div class="miguelSuperior col-9 col-sm-10 text-justify">
          <div>
            Com a cobertura para acidentes pessoais específica para ciclistas,
            você e sua bike estão protegidos para pedalar com a segurança que
            você merece!
            <p></p>
            Escolha abaixo a cobertura que mais te atende. É fácil, rápido e
            cabe no seu bolso!
            <p />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-3">
          <div>
            <div class="texto-superio">Seguro acidentes pessoais</div>
            <div class="font-size-infor mb-3 text-justify"></div>
            <b-alert
              class="mt-3 font-size-infor text-justify"
              show
              variant="info"
            >
              <strong
                >Após fazer o upgrade, a nova cobertura de acidente pessoal
                entrará em vigor na sua próxima apólice com a renovação em
                {{ formatacaoData.formatarDataLocal(dataVencimento) }}.</strong
              >
              Até lá você não será cobrado(a) por isso.</b-alert
            >
            <div class="font-size-infor mb-4">
              Escolha abaixo a cobertura que mais te atende. É fácil, rápido e
              cabe no seu bolso!
            </div>
            <div class="row d-flex justify-content-center">
              <div
                class="mb-3 box-shadow col-xl-4 col-lg-12"
                v-for="(plano, index) in lstPlanosOpcional"
                :key="'planoOpcional' + index"
              >
                <div
                  :class="
                    plano.id == config.ID_PLANO_OURO
                      ? 'card card-body-plano border-success'
                      : 'card card-body-plano '
                  "
                  :style="planoAnual == false ? 'min-height:310px' : ''"
                >
                  <div v-if="plano.id == config.ID_PLANO_OURO">
                    <div class="badge badge-pill badge-success">
                      Recomendado
                    </div>
                  </div>

                  <div class="card-body">
                    <h5>{{ plano.id }}</h5>
                    <div
                      class="idenizacao mb-1"
                      v-if="plano.id == config.ID_PLANO_OURO"
                    >
                      <strong>
                        Indenização:
                        {{ config.DESCRICAO_PLANO_AP_OURO_IDENIZACAO }}</strong
                      >
                      <div class="mb-1">Aplicada no caso de:</div>
                    </div>

                    <div
                      class="idenizacao mb-1"
                      v-if="plano.id == config.ID_PLANO_PRATA"
                    >
                      <strong>
                        Indenização:
                        {{ config.DESCRICAO_PLANO_AP_PRATA_IDENIZACAO }}</strong
                      >
                      <div class="mb-1">Aplicada no caso de:</div>
                    </div>

                    <ul
                      class="plano-detalhes"
                      v-if="plano.id == config.ID_PLANO_OURO"
                    >
                      <li
                        v-for="(item, index) in config.DESCRICAO_PLANO_AP_OURO"
                        :key="'u' + index"
                      >
                        {{ item }}
                      </li>
                    </ul>
                    <ul
                      class="plano-detalhes"
                      v-if="plano.id == config.ID_PLANO_PRATA"
                    >
                      <li
                        v-for="(item, index) in config.DESCRICAO_PLANO_AP_PRATA"
                        :key="'u' + index"
                      >
                        {{ item }}
                      </li>
                    </ul>
                  </div>
                  <div class="card-footer-desktop">
                    <div class="text-center text-muted">
                      <h2
                        class="card-title pricing-card-title"
                        style="color: #23ad21"
                      >
                        {{
                          formatacaoMoeda(
                            planoAnual
                              ? plano.valorAnualComAssistencia24h
                              : plano.valorMensalComAssistencia24h
                          )
                        }}
                        <small v-if="planoAnual" class="text-muted"
                          >/ ano</small
                        >
                        <small v-else class="text-muted">/ mês</small>
                      </h2>
                    </div>

                    <button
                      :disabled="criandoSeguro"
                      class="btn btn-block-desktop btn-success"
                      @click="
                        criarNovoSeguro(
                          plano.id,
                          plano.valorMensalComAssistencia24h
                        )
                      "
                    >
                      Contratar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-12">
                <b-button
                  class="btn-block "
                  variant="outline-success"
                  to="/cliente"
                >
                  Voltar para o painel
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loading :criandoSeguro="criandoSeguro" v-if="criandoSeguro" />
    </div>
    <div class="row desktop">
      <div class="col-3 retangulo-desktop">
        <div class="row">
          <div class="col-12 miguel-desktop">
            <img src="/img/Untitled-1.svg" class="img-miguel-desktop" />
          </div>
          <div class="col-12">
            <div class="bem-vindo-desktop">
              <div>
                Nós pensamos em tudo! E agora além de proteger a sua bike,
                queremos proteger o mais importante, você ciclista.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-9 layout-desktop">
        <div>
          <div class="row mt-5 card card-body" style="border-radius: 20px">
            <div>
              <div class="font-size-infor-desktop-planos">
                Com a cobertura para acidentes pessoais específica para
                ciclistas, você e sua bike estão protegidos para pedalar com a
                segurança que você merece!
                <p></p>
                Escolha abaixo a cobertura que mais te atende. É fácil, rápido e
                cabe no seu bolso!
              </div>
              <div class="row d-flex justify-content-center">
                <div
                  class="mb-3 box-shadow col-xl-4 col-lg-12"
                  v-for="(plano, index) in lstPlanosOpcional"
                  :key="'planoOpcional' + index"
                >
                  <div
                    :class="
                      plano.id == config.ID_PLANO_OURO
                        ? 'card card-body-plano-desktop plano-recomendado-desktop border-success'
                        : 'card card-body-plano-desktop plano-nao-recomendado-desktop'
                    "
                  >
                    <div v-if="plano.id == config.ID_PLANO_OURO">
                      <div class="badge badge-pill badge-success-desktop">
                        Recomendado
                      </div>
                    </div>

                    <div class="card-body">
                      <strong class="card-title-desktop">{{ plano.id }}</strong>
                      <div
                        class="idenizacao mb-1"
                        v-if="plano.id == config.ID_PLANO_OURO"
                      >
                        <strong>
                          Indenização:
                          {{
                            config.DESCRICAO_PLANO_AP_OURO_IDENIZACAO
                          }}</strong
                        >
                        <div class="mb-1">Aplicada no caso de:</div>
                      </div>

                      <div class="idenizacao mb-1" v-else>
                        <strong
                          >Indenização:
                          {{ config.DESCRICAO_PLANO_AP_PRATA_IDENIZACAO }}
                        </strong>
                        <div class="mb-1">Aplicada no caso de:</div>
                      </div>

                      <ul
                        class="texto-card-desktop"
                        style="margin-bottom: 0px; margin-left: -22px"
                        v-if="plano.id == config.ID_PLANO_OURO"
                      >
                        <li
                          v-for="(item,
                          index) in config.DESCRICAO_PLANO_AP_OURO"
                          :key="'u' + index"
                        >
                          {{ item }}
                        </li>
                      </ul>
                      <ul
                        class="texto-card-desktop"
                        style="margin-bottom: 0px; margin-left: -22px"
                        v-if="plano.id == config.ID_PLANO_PRATA"
                      >
                        <li
                          v-for="(item,
                          index) in config.DESCRICAO_PLANO_AP_PRATA"
                          :key="'u' + index"
                        >
                          {{ item }}
                        </li>
                      </ul>
                    </div>
                    <div class="card-footer-desktop">
                      <div class="text-center text-muted">
                        <h4
                          class="pricing-card-title-desktop"
                          style="color: #23ad21"
                        >
                          {{
                            formatacaoMoeda(plano.valorMensalComAssistencia24h)
                          }}

                          <small class="text-muted">/ mês</small>
                        </h4>
                      </div>

                      <button
                        :disabled="criandoSeguro"
                        class="btn btn-block-desktop btn-success"
                        @click="
                          criarNovoSeguro(
                            plano.id,
                            plano.valorMensalComAssistencia24h
                          )
                        "
                      >
                        Contratar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <b-alert class="mt-3" show variant="info">
              <strong
                >Após fazer o upgrade, a nova cobertura de acidente pessoal
                entrará em vigor na sua próxima apólice com a renovação em
                {{ formatacaoData.formatarDataLocal(dataVencimento) }}.</strong
              >
              Até lá você não será cobrado(a) por isso.</b-alert
            >
          </div>
          <div class="my-3">
            <div class="row">
              <div class="col-12">
                <b-button
                  class="sucesso-botao-desktop"
                  variant="outline-success"
                  to="/cliente"
                >
                  Voltar para o painel
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Loading :criandoSeguro="criandoSeguro" v-if="criandoSeguro" />
    </div>
  </div>
</template>

<script>
import auth from "../../auth";
import axios from "axios";
import Swal from "sweetalert2";
import captureError from "../../helpers/captureError";
import config from "../../config";
import formatacaoMoeda from "../../helpers/formatacaoMoeda";
import formatacaoData from "../../helpers/formatacaoData";
import moment from "moment";

import Loading from "../../components/Usuario/Loading.vue";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      config,
      formatacaoData,
      formatacaoMoeda,
      auth,

      idSeguro: undefined,

      //planos
      lstPlanos: [],
      buscandoPlanos: false,

      //novo Seguro
      criandoSeguro: false,
      plano: undefined,
      valorPlano: undefined,
      tamanhoTela: undefined,

      //Plano anual
      planoAnual: false,
      idTipoAssinatura: undefined,
      activeMensal: "btn-success",
      activeAnual: undefined,
      valorPlanoMensal: [],
      urlParceiro: undefined,
      dataVencimento: "",

      //Seguro ap
      lstPlanosOpcional: [],
      valorBike: 0,
      uf: "",
      nascimento: undefined,
    };
  },
  created() {
    this.valorBike = this.$route.params.valorBike;
    this.idSeguro = this.$route.params.id;
    this.dataVencimento = this.$route.params.data;
    this.uf = this.$route.params.uf;
    this.nascimento = this.nascimento;
    this.buscarPlanos();
  },
  methods: {
    async criarNovoSeguro(planoOpcional, valorPlanoOpcional) {
      var result = await Swal.fire({
        title: "Atenção!",
        text:
          "Você está fazendo um upgrade no seu seguro e a partir da próxima renovação sua apólice terá a cobertura acidente pessoal",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4B9C2E",
        confirmButtonText: "Ok, entendi",
        cancelButtonText: "Cancelar",
        cancelButtonColor: "#d33",
      });
      if (result?.isConfirmed == false || result?.dismiss == "cancel") {
        return;
      }
      try {
        this.criandoSeguro = true;

        var data = {
          idSeguro: this.idSeguro,
          planoOpcional: planoOpcional,
          valorPlanoOpcional: valorPlanoOpcional,
        };

        await axios.post(
          `${config.API_URLV2}/seguro/adquirirSeguroAcidentePessoal`,
          data
        );

        this.criandoSeguro = false;
        Swal.fire(
          "Parabéns! ",
          `Você fez o upgrade do seu seguro e adicionou a cobertura de acidente pessoal ${planoOpcional} `,
          "success"
        );
        this.$router.replace("/cliente");
      } catch (error) {
        return this.validacoes(error);
      }
    },
    validacoes(error) {
      this.criandoSeguro = false;
      captureError(error);
      if (error?.response?.data?.error) {
        this.$toast.error(error?.response?.data?.error, {
          duration: 8000,
          position: "top",
        });
      } else {
        Swal.fire({
          title: "Não foi possível assinar seu seguro de acidente pessoal",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
      return false;
    },
    async buscarPlanos() {
      try {
        this.criandoSeguro = true;
        let data = {
          idSeguro: this.idSeguro,
          valorBike: this.valorBike,
          ufCliente: this.uf,
          nascimento: this.nascimento
            ? moment(this.nascimento, "DD/MM/YYYY").format("YYYY-MM-DD")
            : undefined,
        };

        var response = await axios.post(
          `${config.API_URLV2}/seguro/precosPlano`,
          data
        );

        this.lstPlanos =
          response.data.filter((item) => {
            if (
              item.id == config.ID_PLANO_OURO ||
              item.id == config.ID_PLANO_PRATA
            )
              return false;
            else return true;
          }) || [];

        this.lstPlanosOpcional =
          response.data.filter((item) => {
            if (
              item.id == config.ID_PLANO_OURO ||
              item.id == config.ID_PLANO_PRATA
            )
              return true;
            else return false;
          }) || [];

        this.criandoSeguro = false;
        return true;
      } catch (error) {
        captureError(error);
        this.criandoSeguro = false;
        Swal.fire({
          title: "Não foi possível buscar os planos de seguro",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });

        return false;
      }
    },
  },
};
</script>
<style scoped>
.btn-planos-periodo {
  border-radius: 100px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 12px;
}
.btn.disabled,
.btn:disabled {
  opacity: 0.35;
}
.form-control {
  height: calc(1.5em + 1rem + 5px);
  font-size: 1.1rem;
}

.miguelSuperior {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 7px;
  width: 225px;
  right: 05px;
  color: white;
  background: #28a745;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 0px 13px 13px 13px;
  font-size: 15px;
}
.box-line-miguel {
  display: flex;
  flex-direction: column;
}
.texto-superio {
  color: #11481d;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.badge-success {
  color: #fff;
  text-align: left;
  background-color: #28a745;
  margin-top: -7px;
  float: right;
  margin-right: 25px;
  border-radius: 5px;
}
.font-size-infor {
  font-size: 12px;
  font-weight: 500;
}
.btn-block {
  padding: 12px;
  border-radius: 10px;
}
.plano-detalhes {
  margin-left: -22px;
}
/*================== DeskTop =================================*/
.layout-desktop {
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 20px;
}
.retangulo-desktop {
  background: linear-gradient(180deg, #303030 0%, rgba(0, 0, 0, 0) 100%),
    #28a745;
}
.textor-superio-desktop {
  color: #11481d;
  font-weight: 500;
  line-height: 0.2;
  font-size: 12px;
  text-align: center;
}
.row {
  margin: 0;
}

.badge-success-desktop {
  color: #fff;
  text-align: left;
  background-color: #28a745;
  margin-top: -7px;
  float: right;
  margin-right: 25px;
  border-radius: 5px;
}
.font-size-infor-desktop {
  font-size: 18px;
  margin-top: 35px;
  font-weight: 500;
  color: #11481d;
}
.font-size-infor-desktop-planos {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 25px;
  margin-left: 15px;
  color: #11481d;
}
.idenizacao {
  font-size: 13px;
}
.btn-block-desktop {
  border-radius: 5px;
  display: block;
  width: 100%;
  margin-bottom: -1px;
}
.img-miguel-desktop {
  width: 100px;
}

.miguel-desktop {
  text-align: center;
  position: relative;
  margin-top: 98px;
}
.bem-vindo-desktop {
  padding: 21px;
  bottom: 38.92%;
  margin-top: 28px;
  background: #ffffff;
  border-radius: 24px 0px 24px 24px;
  font-size: 14px;
}
.bem-vindo-desktop-2 {
  padding: 21px;
  padding-bottom: 1.92%;
  margin-top: 30px;
  background: #ffffff;
  border-radius: 24px 0px 24px 24px;
  font-size: 14px;
}
.texto-desktop {
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;

  color: #777777;
}

.sucesso-botao-desktop {
  width: 180px;
  border-radius: 10px;
  padding: 11px;
  display: block;
}

.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.card-body-plano-desktop {
  min-height: 270px;
  position: relative;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: #ffffff;
  border: 1px solid #a3a5a3;
  box-sizing: border-box;
  border-radius: 10px;
}

.plano-recomendado-desktop {
  min-height: 320px;
}

.plano-nao-recomendado-desktop {
  margin-top: 20px;
}

.texto-card-desktop {
  font-size: 13px;
}
.card-footer-desktop {
  padding: 0rem 0em;
  background-color: rgb(255 255 255 / 3%);
  border-top: 1px solid rgb(255 255 255 / 13%);
}
.desktop {
  height: 100vh;
}
.mobile {
  display: none;
}
.texto-desconto-desktop {
  font-size: 12px;
  color: #28a745;
  line-height: 19px;
  font-weight: 700;
  background-color: #c4ffc3;
  border-radius: 100px;
  padding-left: 3px;
  padding-right: 7px;
}
.icon-desconto {
  vertical-align: text-top;
  width: 16px;
}
.titulo {
  display: inline-flex;
}
@media (max-width: 768px) {
  .mobile {
    display: block;
  }
}
@media (max-width: 768px) {
  .desktop {
    display: none;
  }
}
@media (max-width: 1198px) {
  .texto-card-desktop {
    font-size: 20px;
  }
  .pricing-card-title-desktop {
    font-size: 35px;
  }
  .card-title-desktop {
    font-size: 27px;
  }
}
</style>
